<template>
    <r-e-dialog title="详情" :visible.sync="dialogVisible" top="5vh" width="1100px" show-footer
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="135px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="安装房间" prop="apartmentUuid">
                    <el-select v-model="formPublish.apartmentUuid" placeholder="请选择" style="width: 225px;">
                        <el-option v-for="item in apartmentList" :key="item.uuid" :label="item.name" :value="item.uuid" />
                    </el-select>
                </el-form-item>
                <el-form-item label="安装人" prop="installationPersonnel">
                    <el-input v-model="formPublish.installationPersonnel" style="width: 225px;" />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="formPublish.phone" style="width: 225px;" />
                </el-form-item>
                <el-form-item label="安装时间" prop="installTime">
                    <el-date-picker v-model="formPublish.installTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" style="width: 225px;" />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="门锁型号" prop="lockModel">
                    <el-input v-model="formPublish.lockModel" disabled style="width: 225px;" />
                </el-form-item>
                <el-form-item label="NB供应商" prop="supplier">
                    <el-radio-group v-model="formPublish.supplier" disabled>
                        <el-radio label="联调">联调</el-radio>
                        <el-radio label="移动">移动</el-radio>
                        <el-radio label="电信">电信</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="NB年限" prop="nbYearNumber">
                    <el-input v-model="formPublish.nbYearNumber" disabled style="width: 225px;" />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="安装前图片" prop="installBefore">
                    <upload-avatar :uuid="formPublish.installBefore" @on-success="handleUploadInstallBeforeSuccess"/>
                </el-form-item>
                <el-form-item label="安装中图片" prop="installing">
                    <upload-avatar :uuid="formPublish.installing" @on-success="handleUploadInstallingSuccess"/>
                </el-form-item>
                <el-form-item label="安装后图片" prop="installAfter">
                    <upload-avatar :uuid="formPublish.installAfter" @on-success="handleUploadInstallAfterSuccess"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import uploadAvatar from "@/components/Upload/upload-avatar";
    import {MessageSuccess} from "@custom/message";
    import {getApartmentListByCommunityUuid, lockInstallSave} from "@/api/lock-install";
    export default {
        name: "dialog-save-lock-install",
        data(){
            return{
                dialogVisible: false,
                apartmentList: [],
                formPublish: {
                    communityUuid: null,
                    apartmentUuid: null,
                    installationPersonnel: null,
                    phone: null,
                    installTime: null,
                    lockModel: "E907P-BN",
                    supplier: "电信",
                    installBefore: null,
                    installing: null,
                    installAfter: null,
                    nbYearNumber: "八年",
                },
                rules: {
                    apartmentUuid: [{required: true, message: '请选择安装房间', trigger: 'change'}],
                    installationPersonnel: [{required: true, message: '请填写安装人', trigger: 'blur'}],
                    phone: [{required: true, message: '请填写安装手机', trigger: 'blur'}],
                    installTime: [{required: true, message: '请选择安装时间', trigger: 'change'}],
                    lockModel: [{required: true, message: '请填写门锁型号', trigger: 'blur'}],
                    supplier: [{required: true, message: '请选择NB供应商', trigger: 'change'}],
                    installBefore: [{required: true, message: '请上传安装前图片', trigger: 'blur'}],
                    installing: [{required: true, message: '请上传安装中图片', trigger: 'blur'}],
                    installAfter: [{required: true, message: '请上传安装后图片', trigger: 'blur'}],
                },
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            }
        },
        components: {uploadAvatar},
        methods:{
            async openDialog(data){
                let that = this;
                let {communityUuid} = data;
                that.formPublish.communityUuid = communityUuid;
                let loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                getApartmentListByCommunityUuid({communityUuid}).then(res => {
                    let {list} = res;
                    that.apartmentList = list;
                    that.dialogVisible = true;
                }).finally(() => loading.close());
            },
            clickSubmit() {
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let loadingOptions = that.loadingOptions;
                        const loading = that.$loading({...loadingOptions});
                        let {
                            communityUuid, apartmentUuid, installationPersonnel, phone, installBefore, installing,
                            installAfter, lockModel, supplier, installTime, nbYearNumber
                        } = that.formPublish;
                        installTime = (new Date(installTime)).getTime();
                        let data = {
                            communityUuid, apartmentUuid, installationPersonnel, phone, installBefore, installing,
                            installAfter, lockModel, supplier, installTime, nbYearNumber
                        };
                        lockInstallSave(data).then(res=>{
                            MessageSuccess('添加成功');
                            that.clickCancel();
                        }).finally(() => loading.close());
                    }
                });
            },
            clickCancel() {
                this.dialogVisible = false;
                this.formPublish = {
                    communityUuid: null,
                    apartmentUuid: null,
                    installationPersonnel: null,
                    phone: null,
                    installTime: null,
                    lockModel: "E907P-BN",
                    supplier: "电信",
                    installBefore: null,
                    installing: null,
                    installAfter: null,
                    nbYearNumber: "八年",
                }
                this.$emit("handleSearch", false);
            },
            async getHydropowerBill(){

            },

            handleUploadInstallBeforeSuccess({info}) {
                // 上传图片
                this.formPublish.installBefore = info.uuid;
            },

            handleUploadInstallingSuccess({info}) {
                // 上传图片
                this.formPublish.installing = info.uuid;
            },

            handleUploadInstallAfterSuccess({info}) {
                // 上传图片
                this.formPublish.installAfter = info.uuid;
            },

        },
        watch: {}
    }
</script>

<style scoped>

</style>